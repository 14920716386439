import { AuthProfile } from "eyam-webui-components";
import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYAMApiResult } from "eyam-webui-models";

class UtilityService extends BaseService {
    private readonly BASE_URL: string = "/api/Utility";

    getCurrentProfile(): Promise<AuthProfile> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<AuthProfile>>(`${this.BASE_URL}/GetCurrentProfile`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
    
    cancelAccessToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<string>>(`${this.BASE_URL}/CancelAccessToken`)
            .then((response) => resolve(response.data.value))
            .catch((error) => this.handleError(error, reject));
        });
    }

    getAuthRules(): Promise<{ [key: string]: string[] }> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<{ [key: string]: string[] }>>(`${this.BASE_URL}/getAuthRules`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

}
export default new UtilityService();