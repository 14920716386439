export class BaseService {

    constructor() {

    }

    protected handleError(error: any, reject: (err?: any) => void) {

        if (error.response.status == 500) {
            if (error.response.data && error.response.data.CorrelationId) {
                reject(new Error(error.response.data.Message));
            }
        }

        if (error.response.data) {
            reject(new Error(error.response.data.message));
        }
    }
}