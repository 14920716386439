import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";

const resources = {
    en: {
        translation: en
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: "-" // we do not use keys in form messages.welcome
});

export default i18n;
