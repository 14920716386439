export default {
    GENERIC: {
        LOADING_ALERT: "Loading",
        AUTENTICATING: "Autenticating"
    },
    GENERIC_BUTTON: {
        SUBMIT: "Submit",
        UPDATE: "Update",
        DELETE: "Delete",
        CANCEL: "Cancel",
        EDIT: "Edit",
        INFO: "Info",
        BACK: "Back"
    },
    GENERIC_ERROR_ALERT: {
        NOT_ALLOWED: "Not Allowed.",
        NOT_FOUND: "Not Found.",
        WENT_WRONG: "Something went wrong.",
        SUBMIT_FAILED: "Submit failed.",
        ERROR: "ERROR.",
        FIELD_VALIDATION_MAXIMUM_LENGTH: "The maximum length for this field is ",
        FIELD_VALIDATION_CHARACTERS_ACCEPTED_LETTERS_DIGITS: "The only characters accepted for this field are letters, digits, '-' and '_' "
    },
    GENERIC_WARNING_ALERT: {
        DELETE_CONFIRMATION: "Are you sure you want to delete this item?"
    },
    GENERIC_LABEL: {
        ID: "Id",
        NAME: "Name",
        YES: "Yes",
        NO: "No",
        DEFAULT: "Default",
        SCAN_QR: "Scan QR",
        ASSETS: "Assets",
        INVENTORY: "Inventory",
        APP: "App",
        SETTINGS: "Settings",
        HEALTH_CHECKS: "Health Checks",
        HEALTH_CHECKS_CONFIG: "Health Checks Config",
        LOGS: "Logs",
        EY_ASSET_MANAGER: "EY Asset Manager",
        ADMIN_WEB_APP: "Admin Web App"
    },
    PAGE_ASSET: {
        LABEL_IMAGES: "Images",
        IMAGE_INPUT: "Take a picture / Select image(s)",
        ERROR_ALERT_NOT_FOUND: "Asset not found."
    },
    PAGE_SETTINGS: {
        LABEL_LANGUAGE: "Language",
        PLACEHOLDER_LANGUAGE: "Select a language",
        LABEL_DARK_MODE: "Dark Mode"
    },
    CF_NAME: {
        SERIAL_NUMBER: "Serial Number",
        LOCATION_ID: "Location",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        LAST_MODIFIED_BY: "Last Modified By",
        LAST_MODIFIED_DATE: "Last Modified Date",
        ASSET_ID: "Asset Id",
        ITEM_ID: "Item Id",
        SKU: "SKU",
        NAME: "Name"
    },
    PAGE_HOME_HEALTH_CHECKS: {
        LABEL_HEALTHY: "Healthy",
        LABEL_UNHEALTHY: "Unhealthy",
        LABEL_DEGRADED: "Degraded",
        HEADER: "Health Checks Dashboard",
        LABEL_ENPOINT_OVERALL_STATUS: "Endpoint Overall Status:",
        LABEL_LAST_CHECKED: "Last Checked:",
        LABEL_ENDPOINT_URI: "Endpoint Uri:",
        LABEL_CURRENT_LOGGING_SETTINGS: "Current Logging Settings:",
        BUTTON_CHANGE_LOG_LEVEL: "Change Log Level",
        NOT_AVAILABLE: "Not available",
        LABEL_CONFIGURED_ENDPOINT: "Configured Endpoint Healthchecks:",
        LABEL_STATUS: "Status:",
        LABEL_DETAILS: "Details:",
        LABEL_CHANGE_LOG_LEVEL: "Change Log Level",
        LABEL_LOGGING_SETTINGS_FOR_ERROR: "Logging Settings for the following endpoints:"
    },
    PAGE_HEALTH_CHECKS_CONFIG: {
        HEADER: "Health Checks Endpoint Configuration",
        BUTTON_ADD_ENDPOINT: "Add Endpoint",
        BUTTON_UPDATE_ENDPOINT: "Update Endpoint",
        LABEL_URI: "URI",
        LABEL_OPERATIONS: "Operations",
        LABEL_SCHEMA: "Schema",
        LABEL_DB_NAME: "Database Name",
        LABEL_ENDPOINT_NAME: "Endpoint Name",
        PLACEHOLDER_ENDPOINT_NAME: "Endpoint Name",
        LABEL_ENDPOINT_URI: "Endpoint URI",
        PLACEHOLDER_ENDPOINT_URI: "Endpoint URI",
        LABEL_ENDPOINT_SCHEMA: "Endpoint Schema",
        PLACEHOLDER_ENDPOINT_SCHEMA: "Endpoint Schema",
        LABEL_ENDPOINT_DB_NAME: "Endpoint Database Name",
        PLACEHOLDER_ENDPOINT_DB_NAME: "Endpoint Database Name",
        FIELD_VALIDATION_NAME_REQUIRED: "Endpoint name is required.",
        FIELD_VALIDATION_URI_REQUIRED: "Endpoint URI is required.",
        FIELD_VALIDATION_SCHEMA_REQUIRED: "Endpoint schema is required.",
        FIELD_VALIDATION_DB_NAME_REQUIRED: "Endpoint database name is required.",
        INVALID_URL: "Endpoint URI format is invalid."
    },
    PAGE_LOGS: {
        HEADER: "Logs",
        PLACEHOLDER_MICROSERVICE: "Microservice",
        PLACEHOLDER_MESSAGE: "Message",
        PLACEHOLDER_REQUEST_ID: "Request Id",
        PLACEHOLDER_TRACE_ID: "Trace Id",
        PLACEHOLDER_ERROR_ID: "Error Id",
        PLACEHOLDER_USER_ID: "User Id",
        PLACEHOLDER_LEVEL: "Level",
        PLACEHOLDER_EXCEPTION: "Exception",
        PLACEHOLDER_CORRELATION_ID: "Correlation Id",
        PLACEHOLDER_LOGS_PER_PAGE: "Logs Per Page",
        LABEL_FILTER: "Filter",
        LABEL_UTC_TIMESTAMP: "UTC Timestamp",
        LABEL_MICROSERVICE: "Microservice",
        LABEL_LEVEL: "Level",
        LABEL_MESSAGE: "Message",
        LABEL_REQUEST_ID: "Request Id",
        LABEL_TRACE_ID: "Trace Id",
        LABEL_ERROR_ID: "Error Id",
        LABEL_PROPERTIES: "Properties",
        LABEL_USER_ID: "User Id",
        LABEL_EXCEPTION: "Exception",
        LABEL_CORRELATION_ID: "Correlation Id",
        LOG_DETAILS: "Log Details",
        LABEL_DETAILS: "Details"
    }
};
