import { EndSessionRequest, InteractionType, IPublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { AuthProfile, EYAMApp, LoaderWrapper, NavigationGroup, NotificationService } from "eyam-webui-components";
import  UtilityService  from "./Services/UtilityService";
import { AuthAreas } from "./Auth/AuthAreas";
import RoutePaths from "./RoutePaths";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import { API_SCOPE, EYDPCA } from "./Auth/AuthConfig";

const HomeViewAsync = React.lazy(() => import("./UI/Scenes/HomeView"))
const HealthChecksViewAsync = React.lazy(() => import("./UI/Scenes/HealthChecks/HealthChecksView"))
const LogsViewAsync = React.lazy(() => import("./UI/Scenes/LogsView"))
const NoMatchPageAsync = React.lazy(() => import("./UI/Scenes/Shared/NoMatchPage"));
const NotAllowedPageAsync = React.lazy(() => import("./UI/Scenes/Shared/NotAllowed"));
const HangfireDashboardViewAsync = React.lazy(() => import("./UI/Scenes/HangfireDashboardView"));


interface AppProps {

}

export const App: React.FunctionComponent<AppProps> = (props: AppProps) => {
    const [cProfile, setAuthData] = useState<AuthProfile>(null);
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(true);
    const [authRules, setAuthRules] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        const initAuth = async () => {
            setIsProcessing(true);
            const msalAccount = await acquireMsalAccount();
            const { account, accessToken } = msalAccount;
            const claims = JSON.parse(window.atob(accessToken.split(".")[1]));

            var profile: AuthProfile = {
                roles : claims.roles,
                displayName : account.name,
                userId: account.homeAccountId
            }
            setAuthData(profile);

            let auth = await UtilityService.getAuthRules();
            setAuthRules(auth);
            setIsProcessing(false);
        }
        initAuth();
        
    }, []);
    
    const acquireMsalAccount = async () => {
        const activeAccount = EYDPCA.getActiveAccount();
        const accounts = EYDPCA.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            throw "User not logged in";
        }

        const request = {
            scopes: [API_SCOPE],
            account: activeAccount || accounts[0]
        };

        let authRes: AuthenticationResult = null;

        try {

            authRes = await EYDPCA.acquireTokenSilent(request);

        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {

                try {
                    await EYDPCA.acquireTokenRedirect(request);
                } catch (err2) {
                    console.log(err2);
                }
            } else {
                throw err;
            }
        }

        return authRes;
    };
    
    const logoutHandler = async () => {
        const allAccounts = EYDPCA.getAllAccounts();
        const account = EYDPCA.getActiveAccount() || allAccounts[0];
        const logoutRequest: EndSessionRequest = {
            account,
            postLogoutRedirectUri: window.location.origin
        }
        setIsProcessing(true);
        try {
            await UtilityService.cancelAccessToken();
            await EYDPCA.logoutRedirect(logoutRequest);
        } catch (error) {
            NotificationService.showError(error + "", t("GENERIC_BUTTON_OK"), error)
        }
        finally {
            setIsProcessing(false);
        }
    }

    let navigationConfig: NavigationGroup[] = [];

    navigationConfig.push({
        name: "",
        area: AuthAreas.MAIN_AREA,
        items: [
            { viewTitle: t("PAGE_HOME_HEALTH_CHECKS-HEADER"), view: <HomeViewAsync />, path: RoutePaths.HOME },
            { viewTitle: t("PAGE_HEALTH_CHECKS_CONFIG-HEADER"), view: <HealthChecksViewAsync />, path: RoutePaths.HEALTHCHECKS },
            { viewTitle: t("Hangfire"), view: <HangfireDashboardViewAsync />, path: RoutePaths.HANGFIRE_DASHBOARD },
            { viewTitle: t("PAGE_LOGS-HEADER"), view: <LogsViewAsync />, path: RoutePaths.LOGS }
        ]
    });
    
    return (
        <LoaderWrapper inverted active={isProcessing}>
             {!isProcessing && <EYAMApp
                navigationConfig={navigationConfig}
                areaAccessRules={authRules}
                userProfile={cProfile}
                notAllowedRoute={RoutePaths.NOT_ALLOWED}
                renderNotAllowedPage={() => <NotAllowedPageAsync/>}
                notFoundRoute={RoutePaths.NOT_FOUND}
                renderNotFoundPage={() => <NoMatchPageAsync/>}
                homeRoute={RoutePaths.HOME}
                appLogoPath={"/images/logo_simple_xs.png"}
                renderSceneError={() => { return <>Something went wrong</> }}
                onLogout={() => logoutHandler()}
                sidebarWidth={250}
            />
        }
        </LoaderWrapper>
    );
};

export const AppAuthWrapper: React.FunctionComponent<{}> = () => {

    const authRequest = {
        scopes: ["openid", "profile"]
    };

    const renderAuthLoader = () => {
        return <Dimmer active><Loader content={"Loading"} inline="centered" active /></Dimmer>
    }

    return (
         <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={() => <div>error</div>}
            loadingComponent={() => renderAuthLoader()}>
            <App/>
        </MsalAuthenticationTemplate>
    );
};

