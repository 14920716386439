import "core-js";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { AppAuthWrapper } from "./app/App";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-less/semantic.less";
import "./app/Localization/i18n";

import "./scss/appstyles.scss";
import { MsalProvider } from "@azure/msal-react";
import { EYDPCA } from "./app/Auth/AuthConfig";
import { SpaSettings } from "eyam-webui-components";
import { createRoot } from 'react-dom/client';
import { Dimmer, Loader } from 'semantic-ui-react';


declare global {
    interface Window {
        spaSettings: SpaSettings
    }
}

const container = document.getElementById('approot');
const root = createRoot(container!);

root.render(<MsalProvider instance={EYDPCA}>
    <BrowserRouter>
        <Suspense fallback={<Dimmer><Loader size='big'></Loader></Dimmer>}>
            <AppAuthWrapper />
        </Suspense>
    </BrowserRouter>
</MsalProvider>);